import React from 'react'
import PropTypes from 'prop-types'
import LayoutListView from '../components/LayoutListView'
import TestimonialList from '../components/TestimonialList'

const Testimonials = ({ location }) => {
  const configID = '7pKvLUWNvFH4BavbqAl6gQ'

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <TestimonialList type={null} isCarousel={false} count={99} />
    </LayoutListView>
  )
}

export default Testimonials

Testimonials.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
